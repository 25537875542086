import { Injectable } from '@angular/core';

import { CanActivate } from '@angular/router';

@Injectable()
export class RouterGuard implements CanActivate {
  constructor() { }

  canActivate() {
    const userData = localStorage.getItem('darkCarz-admin-data');
    if (userData) {
      return true;
    } else {
      return false;
    }

  }
}






/*
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {st} from '@angular/core/src/render3';

@Injectable({
  providedIn: 'root'
})
export class RouterGuard implements CanActivate {
  public userInfo = JSON.parse(localStorage.getItem('darkCarz-admin-data'));
  constructor(private _router: Router, private toaster: ToastrService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (next.data.dashboard !== undefined && (next.data.dashboard !== this.userInfo.dashboard)) {
      console.log('dashboard');
      return true;
    } /!*else if (next.data.map !== undefined && next.data.map !== this.userInfo.map) {
      console.log('map');
      return true;
    }*!/ else if (next.data.workers !== undefined && next.data.workers !== this.userInfo.professionals) {
      console.log('professionals');
      return true;
    } else if (next.data.users !== undefined && next.data.users !== this.userInfo.users) {
      console.log('users');
      return true;
    } else if (next.data.jobs !== undefined && next.data.jobs !== this.userInfo.jobs) {
      console.log('jobs');
      return true;
    } else if (next.data.promoCodes !== undefined && next.data.promoCodes) {// !== this.userInfo.promoCodes && next.data.promoCodesView !== this.userInfo.promoCodes) {
      console.log('promoCodes');
      return true;
    } else if (next.data.configurations !== undefined && next.data.configurations) {// !== this.userInfo.configurations && next.data.configurationsView !== this.userInfo.configurations) {
      console.log('configurations');
      return true;
    } else if (next.data.service !== undefined && next.data.service) {// !== this.userInfo.configurations && next.data.configurationsView !== this.userInfo.configurations) {
      console.log('service');
      return true;
    } else if (next.data.referrals !== undefined && next.data.referrals !== this.userInfo.referrals && next.data.referralsView !== this.userInfo.referrals) {
      console.log('referrals');
      return true;
    } else if (next.data.rolesAndSecurity !== undefined && next.data.rolesAndSecurity !== this.userInfo.rolesAndSecurity && next.data.rolesAndSecurityView !== this.userInfo.rolesAndSecurity) {
      console.log('roleAndSecurity');
      return true;
    } else if (next.data.banks !== undefined && next.data.banks !== this.userInfo.banks && next.data.banksView !== this.userInfo.banks) {
      console.log('banks');
      return true;
    } else if (next.data.payouts !== undefined && next.data.payouts !== this.userInfo.payouts) {
      console.log('payouts');
      return true;
    } else if (next.data.notifications !== undefined && next.data.notifications !== this.userInfo.notifications) {
      console.log('notifications');
      return true;
    } else if (next.data.privacyPolicy !== undefined && next.data.privacyPolicy !== this.userInfo.privacyPolicy && next.data.privacyPolicyView !== this.userInfo.privacyPolicy) {
      console.log('privacyPolicy');
      return true;
    } else if (next.data.termsAndConditions !== undefined && next.data.termsAndConditions !== this.userInfo.termsAndConditions && next.data.termsAndConditionsView !== this.userInfo.termsAndConditions) {
      console.log('termsAndConditions');
      return true;
    } else if (next.data.company !== undefined  && next.data.company) {
      console.log('Company');
      return true;
    }
    else{
    // navigate to not found page
    this._router.navigate(['/']);
    console.log('/amps');
    this.toaster.error('Not Allowed', 'You have been logged out');
    localStorage.removeItem('darkCarz-admin-data');
    return false;
    }

  }
}
*/
