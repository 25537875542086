// This module is being imported from @angular/core.

import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environment';
import { AgmDirectionModule } from 'agm-direction';

@NgModule({
    imports: [

        AgmCoreModule.forRoot({
            apiKey: environment.mapApiKey,
            libraries: ['places', 'drawing', 'geometry'],
        }),
        AgmDirectionModule,
    ],
    exports: [

        AgmCoreModule,
        AgmDirectionModule,

    ],

    declarations: [],
})
export class AgmMapOrignalModule { }
